<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx ali-c">
          <el-row>
            <el-input
              placeholder="订单编号"
              class="search-input"
              clearable
              v-model="searchObj.orderNumber"
            ></el-input>
            <el-input
              placeholder="手机号"
              clearable
              class="search-input ml-15"
              v-model="searchObj.phone"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              class="ml-15"
              @click="search"
              >查询</el-button
            >
            <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
              >重置</el-button
            >
          </el-row>
          <el-row class="width">
            <el-radio-group
              v-model="searchObj.refundStatus"
              @change="tStatusChange"
            >
              <el-radio-button :label="0">待审核</el-radio-button>
              <el-radio-button :label="1">已审核</el-radio-button>
            </el-radio-group>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 表格 -->
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            v-if="searchObj.refundStatus == 0"
          >
            <el-table-column
              label="订单编号"
              prop="orderNumber"
              width="200px"
            ></el-table-column>
            <el-table-column label="客户昵称" prop="nickname"></el-table-column>
            <el-table-column
              label="手机号码"
              prop="telephone"
            ></el-table-column>
            <el-table-column
              label="所属门店"
              prop="storeName"
            ></el-table-column>
            <el-table-column label="订单金额">
              <template slot-scope="scope">
                <span>￥{{ scope.row.actualPay || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单状态">
              <template slot-scope="scope">
                <span :class="scope.row.beforeRefundStatus == 3 ? 'red' : ''">{{ scope.row.beforeRefundStatus == 3 ? '待退货审核' : getString(1, scope.row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="申请退款前状态">
              <template slot-scope="scope">
                <span>{{ getString(1, scope.row.beforeRefundStatus) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="下单时间"
              prop="createTime"
            ></el-table-column>
            <el-table-column label="操作" width="300">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
<!--                  <el-button @click="refund(scope.row, 1)" type="text"-->
<!--                    >接受退款</el-button-->
<!--                  >-->
<!--                  <el-button @click="refund(scope.row, 0)" type="text"-->
<!--                    >拒绝退款</el-button-->
<!--                  >-->
                  <el-button type="text" @click="showRefundDialog(scope.row)">审核</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <el-table class="mt-24 member-table" :data="tableData" v-else>
            <el-table-column
              label="订单编号"
              prop="orderNumber"
            ></el-table-column>
            <el-table-column label="客户昵称" prop="nickname"></el-table-column>
            <el-table-column
              label="手机号码"
              prop="telephone"
            ></el-table-column>
            <el-table-column
              label="所属门店"
              prop="storeName"
            ></el-table-column>
            <el-table-column label="订单金额">
              <template slot-scope="scope">
                <span>￥{{ scope.row.actualPay || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单状态">
              <template slot-scope="scope">
                <span>{{ getString(1, scope.row.status) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="下单时间"
              prop="createTime"
            ></el-table-column>
            <el-table-column label="商品退还状态" prop="goodsReturnStatus"></el-table-column>
            <el-table-column label="审核意见" prop="refundOptions"></el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="search"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 拒绝接单弹窗 start -->
    <el-dialog
      :visible.sync="refuseVisible"
      width="450px"
      :title="title"
      @close="cancel"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="reason">
          <el-input
            v-model="form.reason"
            placeholder="请输入理由"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flx-row ali-c js-fe mt-50">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm('form')">确定</el-button>
      </div>
    </el-dialog>
    <!-- 拒绝接单弹窗 end -->
    <!-- 退款弹窗 -->
    <el-dialog
            :visible.sync="refundDialog.visible"
            width="450px"
            :title="refundDialog.title"
            @close="refundDialogCancel"
            :close-on-click-modal="false"
    >
      <el-row>
        <label>订单号：<span>{{refundDialog.orderNumber}}</span></label>
      </el-row>
      <el-row>
        <label>订单状态：<span>{{getString(1, refundDialog.beforeRefundStatus)}}</span></label>
      </el-row>
      <el-form :model="refundDialog.form" :rules="refundDialog.rules" ref="refundForm">
        <el-form-item prop="goodsReturnStatus" label="商品退还状态">
          <el-input
                  v-model="refundDialog.form.goodsReturnStatus"
                  placeholder="请输入商品退还状态"
                  type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item prop="refundOptions" label="审核意见">
          <el-input
                  v-model="refundDialog.form.refundOptions"
                  placeholder="请输入审核意见"
                  type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flx-row ali-c js-fe mt-50">
        <el-button type="primary" @click="agreeRefund('refundForm')">同意</el-button>
        <el-button @click="rejectRefund('refundForm')">拒绝</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  printingBill,
  updateOrderData2,
  regression,
  listOfPage,
  backstage,
} from "@/api/transactions/order/orders_refund";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      reasonOptions: [
        {
          label: "缺货",
          value: "缺货",
        },
      ],
      form: { reason: "" },
      rules: {
        reason: [
          {
            required: true,
            trigger: "blur",
            message: "理由不能为空",
          },
        ],
      },
      qrText: "",
      captureObj: {},
      img: "",
      dialogVisible: false,
      //搜索栏相关数据
      searchObj: {
        orderNumber: "",
        phone: null,
        storeId: null,
        orderType: null,
        refundStatus: 0,
      },
      // 订单状态与门店列表
      order_status: [
        {
          id: 0,
          title: "未支付",
        },
        {
          id: 1,
          title: "已支付",
        },
        {
          id: 2,
          title: "已发货",
        },
        {
          id: 3,
          title: "已完成",
        },
        {
          id: 4,
          title: "待退款审核",
        },
        {
          id: 5,
          title: "已退款",
        },
        {
          id: 6,
          title: "已失效",
        },
        {
          id: 7,
          title: "已拒单",
        },
        {
          id: 8,
          title: "待配送",
        },
        {
          id: 9,
          title: "配送中",
        },
        {
          id: 10,
          title: "取货中",
        },
        {
          id: 12,
          title: "待调度",
        },
        {
          id: -1,
          title: "退款失败",
        },
        {
          id: 20,
          title: "已超自提时间"
        },
        {
          id: 21,
          title: "妥投异常物品返回中"
        },
        {
          id: 22,
          title: "妥投异常物品已归还"
        }
      ],
      order_type: [
        {
          id: 2,
          title: "积分活动",
        },
        {
          id: 1,
          title: "砍价活动",
        },
        {
          id: 6,
          title: "多人拼团",
        },
        {
          id: 7,
          title: "限时抢购",
        },
      ],
      store_list: [],
      // 会员表格数据内容
      tableData: [],
      //用于分页
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      totalNum: 0,
      url: "/order-service/order/smallProgram?export=1",
      refuseVisible: false,
      row: {},
      status: "",
      title: "",
      refundDialog: {
        visible: false,
        title: "订单审核",
        id: null,
        orderNumber: "",
        beforeRefundStatus : null,
        form: {
          goodsReturnStatus: "",
          refundOptions: ""
        },
        rules: {
          goodsReturnStatus: [
            {
              required: true,
              trigger: "blur",
              message: "商品退还状态不能为空",
            },
          ],
          refundOptions: [
            {
              required: true,
              trigger: "blur",
              message: "审核意见不能为空",
            },
          ],
        }
      }
    };
  },
  mounted() {
    this.getStores();
    this.initData();
  },
  methods: {
    //初始化状态  	refundStatus退款审核状态，0：未审核，1：已审核
    //type 角色类型（0 平台管理员，1 运营人员，2 财务人员  3.门店管理人员）
    initData() {
      if (this.$store.getters.storeId) {
        //门店管理员
      
          this.searchObj.storeId = this.$store.getters.storeId;
        
      }
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        orderNumber: this.searchObj.orderNumber,
        telephone: this.searchObj.phone,
        refundStatus: this.searchObj.refundStatus,
        storeId: this.searchObj.storeId,
      };
      backstage(data).then((res) => {
        this.tableData = res.data.body.body.list;
        this.total = res.data.body.body.total;
      });
    },
    // 打印小票
    printTicket(id) {
      printingBill(id).then((res) => {
        this.$message.success("打印成功");
      });
    },
    changeStatus(row, status) {
      this.$confirm(status == 8 ? "确定收货吗" : "确定拣货吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.doChangeStatus(row, status);
      });
    },
    // 改变状态
    doChangeStatus(row, status) {
      let data = {
        id: row.id,
        orderNumber: row.orderNumber,
        status,
        rejectionReason: status == 7 ? this.form.reason : undefined,
      };
      updateOrderData2(data).then((res) => {
        if (status == 7) {
          regression({
            id: row.id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: "拒单成功",
            });
            this.cancel();
            this.initData();
          });
        } else {
          let msg = "";
          switch (status) {
            case 8:
              msg = "确认接单成功";
              break;
            case 9:
              msg = "确认拣货成功";
              break;
          }
          this.$message.success(msg);
          this.initData();
        }
      });
    },
    confirm(formName) {
      let form = this.$refs[formName];
      this.submitValid(this, form, this.doSubmit);

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.refuseVisible = true;
          let data = {
            status: 8,
            refundStatus: this.status,
            id: this.row.id,
          };
          updateOrderData2(data).then((res) => {
            this.refuseVisible = true;
          });
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.$refs.form.resetFields();
      this.refuseVisible = false;
    },
    // 拒绝接单
    refuse(row, status) {
      this.title = status == 1 ? "请确认是否接受退款" : "请确认是否拒绝退款";
      this.refuseVisible = true;
      this.row = row;
      this.status = status;
    },
    //退款
    refund(row, status) {
      // console.log(row, status);
      this.$confirm(
        status == 1 ? "请确认是否接受退款?" : "请确认是否拒绝退款",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        if (status == 1) {
          regression({
            id: row.id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: "接受退款成功",
            });
            this.initData();
          });
        } else {
          let data = {
            status: 8,
            refundStatus: 1,
            id: row.id,
          };
          updateOrderData2(data).then((res) => {
            this.$message({
              type: "success",
              message: "拒绝退款成功",
            });
            this.initData();
          });
        }
      });
    },

    getDetail(row) {
      smallProgram(row.id).then((res) => {
        this.captureObj = res.data.body;
        this.captureObj.storeName = row.storeName;
        this.captureObj.phone = row.phone;
        this.captureObj.nickname = row.nickname;
        this.qrText = `{ "code": ${this.captureObj.qrCode},"type":0,"title":"订单" }`;
        this.totalNum = 0;
        this.captureObj.list.map((item) => {
          this.totalNum += item.goodsNum;
        });
        this.dialogVisible = true;
      });
    },

    // 获取门店列表
    getStores() {
      listOfPage().then((res) => {
        this.store_list = res.data.body.list;
      });
    },

    // 重置搜索条件
    reset() {
      this.searchObj = {
        orderNumber: "",
        phone: null,
        status: null,
        storeId: null,
        orderType: null,
        refundStatus: 0,
      };
      this.initData();
    },
    tStatusChange() {
      this.initData();
    },
    // 获取订单状态与类型
    getString(type, val) {
      let temp = "";
      if (type == 1) {
        this.order_status.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      } else {
        this.order_type.forEach((e) => {
          if (e.id == val) {
            temp = e.title;
          }
        });
      }
      return temp;
    },
    search() {
      this.initData();
    },
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
    showRefundDialog(row) {
      this.refundDialog.id = row.id
      this.refundDialog.visible = true;
      this.refundDialog.orderNumber = row.orderNumber;
      this.refundDialog.beforeRefundStatus = row.beforeRefundStatus;
    },
    refundDialogCancel() {
      this.refundDialog.form.goodsReturnStatus = "";
      this.refundDialog.form.refundOptions = "";
    },
    /**
     * 同意退款
     */
    agreeRefund(formName) {
      this.$refs[formName].validate((valid) =>{
        if(valid) {
          let data = {
            id: this.refundDialog.id,
            goodsReturnStatus: this.refundDialog.form.goodsReturnStatus,
            refundOptions: this.refundDialog.form.refundOptions
          };
          regression(data).then(res => {
            this.$message({
              type: "success",
              message: "同意退款成功",
            });
            this.refundDialog.visible = false;
            this.initData();
          })
        } else {
          return false;
        }
      });
    },
    /**
     * 拒绝退款
     */
    rejectRefund(formName) {
      this.$refs[formName].validate(valid => {
        if(valid) {
          let data = {
            status: 8,
            refundStatus: 1,
            id: this.refundDialog.id,
            goodsReturnStatus: this.refundDialog.form.goodsReturnStatus,
            refundOptions: this.refundDialog.form.refundOptions
          };
          updateOrderData2(data).then((res) => {
            this.$message({
              type: "success",
              message: "拒绝退款成功",
            });
            this.refundDialog.visible = false;
            this.initData();
          });
        } else {
          return false;
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.txt-tip-info {
  cursor: pointer;
}
.capture {
  padding: 20px;
  color: #000;
}
.capture div {
  margin-bottom: 5px;
}
.captureTable {
  border-collapse: collapse;
  border-top: solid #ccc 1px;
  border-left: solid #ccc 1px;
  width: 100%;
  margin-bottom: 5px;
}
.captureTable td,
.captureTable th {
  border-right: solid #ccc 1px;
  border-bottom: solid #ccc 1px;
  text-align: center;
  height: 40px;
}
.hr {
  height: 1px;
  width: 100%;
  background: #999;
  margin-bottom: 5px;
  display: inline-block;
}
.question {
  font-size: 30px;
  color: orange;
  margin-right: 5px;
}
.title1 {
  font-size: 18px;
  color: #333;
  font-size: weight;
  margin-bottom: 5px;
}
.title2 {
  font-size: 14px;
  color: #999;
}
.width {
  margin-top: 10px;
}
.red {
  color: red;
}
</style>
