import request from '@/utils/request'
// 打印小票
export function printingBill (id) {
    return request({
        url: `/order-service/orderOSM/printingBill/${id}`,
        method: 'get'
    })
}
// 改变状态
export function updateOrderData2 (data) {
    return request({
        url: '/order-service/orderOSM/updateOrderData2',
        method: 'post',
        data
    })
}
//接受退款成功
export function regression (data) {
    return request({
        url: '/order-service/orderOSM/regression',
        method: 'post',
        data
    })
}
// 获取详情
export function smallProgram (id) {
    return request({
        url: `/order-service/order/smallProgram/${id}`,
        method: 'post',
    })
}
// 获取订单
export function backstage (data) {
    return request({
        url: '/order-service/orderOSM/backstage',
        method: 'post',
        data
    })
}
// 获取门店
export function listOfPage () {
    return request({
        url: `/small-inteface/goods-service/store/listOfPage`,
        method: 'get'
    })
}